import { Component,OnInit } from '@angular/core';
import { Injectable, ViewChildren } from '@angular/core';
import {  ViewChild, OnDestroy } from '@angular/core';
import {AngularFireObject, AngularFireDatabase, AngularFireList } from 'angularfire2/database';
// import { Subscription, Observable } from 'rxjs';
// import { MatPaginator, MatSort,MatInput,MatFormField,MatFormFieldControl } from '@angular/material';
import { Validators, FormGroup, FormBuilder,FormControl,ReactiveFormsModule,FormsModule } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
//import firebase = require('firebase');
 //import { NgbModule } from '@bootstrap/ngx-bootstrap';
 import * as firebase from 'firebase';
 import { Observable } from 'rxjs';
 import { map } from 'rxjs/operators';
import { Window } from 'selenium-webdriver';
// import { start } from 'repl';
import { filter } from 'rxjs/operators';
import { Ng2SearchPipeModule } from 'ng2-search-filter'; //importing the module
import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { auth } from 'firebase';



interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
    type: 'success',
    message: 'This is an success alert',
  }, {
    type: 'info',
    message: 'This is an info alert',
  }, {
    type: 'warning',
    message: 'This is a warning alert',
  }, {
    type: 'danger',
    message: "لطفاً أدخل بيانات صحيحة",
  }, {
    type: 'primary',
    message: 'This is a primary alert',
  }, {
    type: 'secondary',
    message: 'This is a secondary alert',
  }, {
    type: 'light',
    message: 'This is a light alert',
  }, {
    type: 'dark',
    message: 'This is a dark alert',
  }
];
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  alerts: Alert[];
  // export class AppComponent implements OnInit{
    cat : any=[];
    cat2 : any=[];
    err : boolean = false;
    errreg:boolean=false;
    IsLogin:boolean=false;
    succ : boolean = false;
    succreg : boolean = false;
    succlogin:boolean=false;
    errlogin:boolean=false;
    IsmodelShow:boolean=false;
    IsmodelShowlog:boolean=false;
    post : any[];
    msg : any;
    type:any;
    dis:any;
    lastPosts : any[]; //Observable<any>;
    ContactCount : any;
    CategoriesCount : any;
    registerForm: FormGroup;
    filterForm:FormGroup;
    registerFormUpdate:FormGroup;
    registerForm1: FormGroup;
    registerFormforget:FormGroup;
    registerFormLogin:FormGroup;
    registerFormRegister:FormGroup;
    registerFormChange:FormGroup;
    submitted = false;
    Mobile : any;
    phone:any;
    emm :any;
    image : any;
    Homename:any;
    zImage:any;
    date = new Date();
    mainTitle : any;
    posts : any;
    welcomeText:any;
    websiteName :any;
    filter:any;
    searchText : any[];
    key: string = 'name'; //set default
    reverse: boolean = false;
    sort(key){
      this.key = key;
      this.reverse = !this.reverse;
    }
  
    //initializing p to one
    p: number = 1;
    constructor(private formBuilder: FormBuilder,private db: AngularFireDatabase,public coolDialogs: NgxCoolDialogsModule) { 
      
     }
  // ...
  ngOnInit() {
   
    if (localStorage.getItem("UserKey") != null)
    {
  
   this.db.list('Users/'+localStorage.getItem("UserKey")).valueChanges().subscribe(t=>{
              
    localStorage.setItem("UserName", t[5].toString());
    this.Homename = localStorage.getItem("UserName");

    this.phone = t[7];
    this.emm = t[2];
  });
  this.IsLogin = true;

    } else
    {
      this.IsLogin = false;
    }

    if(this.filter == ''){
      this.dis = true;
    }
    // this.db.list('/posts', {
    //   query: {
    //     orderByChild: 'Title',
    //     limitToFirst: 10,
    //     startAt: start,
    //     endAt: end
    //   }
    // });
  


   // const d = firebase.database();
    //var query = d.ref('posts').orderByChild('date').limitToLast(2);
    // let items1 = [];
    // let items2 = [];
    //this.lastPosts.map((items: Array<any>) => items as Array<any>);
   // query.on('child_added',k=>{
      // console.log("ss : ",k.val());
       //console.log("kk : " , v);
      // this.lastPosts = k.val();
      //  k.forEach(item => {
      //     console.log('Item:', item.val());
          //this.lastPosts = item.val();
          //items1.push(item.val());
          //console.log('Item2:', items1[0]);
          //
          // items1.forEach(item2 => {
          //   items2.push(item2.val());
          // console.log('Item3:', this.lastPosts);
          
          // });
          // if(items1.length < 2){
          //   items1.push(item.val());
          //   }
          //   this.lastPosts = items1[0];
            //console.log('i222 :', this.items);

          // item.forEach(i => {
          //   console.log('i:', i.val());
            
          // });
        // });
      // });
      //this.lastPosts = items1[0];
    //console.log('i222 :', this.lastPosts[0]);

    //});

    
    //this.books =  
  //console.log(this.db.list('/Categories').valueChanges().subscribe(d=>{
        //this.dataSource.data = d;
       // this.cat = this.db.list('/Categories');//this.getCategories();
      //   this.db.list('Categories').valueChanges().subscribe(d=>{ //,ref => ref.orderByChild("title")
      //   this.cat = d;   
      //   console.log("ffdd ",d);
  
      // });
  
      // this.db.list('posts',ref => ref.orderByChild("date").limitToLast(2)).valueChanges().subscribe(d=>{
      //   d.forEach(e => {
      //     console.log("yyy ",e);
      //   });
      //   console.log("ffwww ",d);
  
      // });
      
      // var ref = firebase.app().database().ref();
      // var peopleRef = ref.child('posts');
      // var peopleUrl = peopleRef.toString() + '.json?shallow=true';
      // console.log(peopleUrl);
  
      this.db.list('Contacts').valueChanges().subscribe(d=>{
        this.ContactCount = d.length;  
        //console.log("ff ",d);
  
      });
      this.db.list('AllPosts').valueChanges().subscribe(d=>{
        this.posts = d.length;  
        //console.log("ff ",d);
  
      });
      //this.db.list('/Categories').query()

      this.db.list('ContactMobile').valueChanges().subscribe(d=>{
        this.Mobile = d;  
  
      });
      
      this.db.list('AllPosts',ref => ref.orderByChild("date").limitToLast(2)).valueChanges().subscribe(d=>{
        this.lastPosts = d;  
        //console.log("ff ",d);
  
      });

      // var r2 = [];
      // this.db.list('AllPosts',ref => ref.orderByChild("date").limitToLast(1000)).stateChanges().subscribe(d2=>{
      
      //  if(d2.payload.child("subCatId").val() == "" && d2.payload.child("image").val() != "" && r2.length < 2){
      //   r2.push({v : d2.payload.toJSON(),k : d2.key});
      //  }
          // console.log(snapshot, snapshot.payload.val());
            //this.article = snapshot;
            //console.log(d2.key,d2.payload.toJSON());
        //});
         //this.article = d2.payload.toJSON();                          
        
      //});
      //console.log("ff ",r2);
     // this.lastPosts = r2;
     var d_ref22 = this.db.list('/SubCategories/');
     d_ref22.valueChanges().forEach(function(snap2){
       //console.log("ssm : ",snap2.keys());
     });


      this.db.list('Settings').valueChanges().subscribe(t=>{
        this.mainTitle = t[9];
        this.welcomeText = t[15];
        this.websiteName = t[14];
        this.image = t[6];
        this.zImage = t[16];
        
      });

    
  
      this.db.list('Categories',ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(d=>{
        this.CategoriesCount = d.length;  
        //console.log("ff ",d);
  
      });
    
     
    this.registerForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      content: ['', Validators.required],
      phone: [''],
      type : 3,
      Status:false,
      date:new Date().getTime()

  }, {
      // validator: MustMatch('password', 'confirmPassword')
  });

  this.registerForm1 = this.formBuilder.group({
    fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      content: ['', Validators.required],
      phone: [''],
      type : 1,
      Status:false,
      date:new Date().getTime()
}, {
    // validator: MustMatch('password', 'confirmPassword')
});

this.registerFormRegister = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    userType : 0,
    userId:'',
    userImage:'',
    DOB:'',
    fcmToken:'',
    password:['', Validators.required],
    repassword:['', Validators.required],
    gender:'',
    os:'web',
    userStatus:0,
    createdAt:new Date().getTime()
}, {
  // validator: MustMatch('password', 'confirmPassword')
});
this.registerFormLogin = this.formBuilder.group({
  email1: ['', [Validators.required, Validators.email]],
  password1: ['', Validators.required],
  
}, {
  // validator: MustMatch('password', 'confirmPassword')
});

this.registerFormChange = this.formBuilder.group({
  oldpassword: ['', [Validators.required]],
  newpassword: ['', Validators.required],
  repassword: ['', Validators.required]

}, {
  // validator: MustMatch('password', 'confirmPassword')
});

this.registerFormUpdate = this.formBuilder.group({
  fname: ['', [Validators.required]],
  fphone: ['', Validators.required],
}, {
  // validator: MustMatch('password', 'confirmPassword')
});


this.registerFormforget = this.formBuilder.group({
  frgemail: ['', [Validators.required]]
}, {
  // validator: MustMatch('password', 'confirmPassword')
});
this.db.list('Categories',ref => ref.orderByChild("IsActive").equalTo(1).limitToLast(6)).valueChanges().subscribe(d=>{
  this.cat = d;  
  //console.log("ff ",d);

});

//this.db.list('posts',ref => ref.orderByChild("title").limitToLast(6)).valueChanges().subscribe(d=>{
 // this.post = d;  
  //console.log("ff ",d);

//});

// this.post.forEach(item => {
//   console.log('Item:', item);
// });
this.db.list('Categories',ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(r=>{ //,k=>k.orderByChild('title').equalTo(q)
this.cat2 = r;  
console.log(this.cat2);
})

this.filterForm= this.formBuilder.group({
  filter: ['', Validators.required]
}, {
  // validator: MustMatch('password', 'confirmPassword')
});
}
  
// search($event) {
//   let q = $event.target.value
//   //alert(q);
 
// }

red(){
  //alert(filter);
  var filter = this.filterForm.getRawValue().filter;
  if(filter != '' && filter != undefined && filter != ' '&& filter != null){
  window.location.href = "/search/"+filter;
  }else{
    return ;
  }
}
  get f() { return this.registerForm.controls; }
  
  close(){
    //alert("");
    this.errreg = false;

    return;
  }

  close2(){
    //alert("");
    this.succ = false;

    return;
  }
  chng(){
    document.getElementById("modal_Profile").click();
  }
  close11(){
    //alert("");
    this.errlogin = false;

    return;
  }

  closelogin(){
    //alert("");
    this.succlogin = false;

    return;
  }
  closereg(){
    //alert("");
    this.errreg = false;

    return;
  }
  cl(){
  this.IsmodelShow=true;
  }
  fr(){
    //this.IsmodelShowlog=true;
    document.getElementById("modal_login").click();

  }
  closesucc(){
    //alert("");
    this.succreg = false;

    return;
  }
logout(){
  var r = confirm("هل تريد تسجيل الخروج ؟");
  if (r == true) {
  auth().signOut().then(() => {
  })

 localStorage.clear();
 window.location.href = "home";
}else{
  
}
}



    onSubmitx() {
        this.submitted = true;
        //email: new FormControl();
          //this.date = new Date(this.date).valueOf();       
        // stop here if form is invalid

        if (this.registerForm.invalid) {
            //alert("لطفاً أدخل بيانات صحيحة");
            this.err = true;
                   return;
        }else{
          var k = this.db.list('Contacts').push({});
          k.set(this.registerForm.value)
            .then(_ => {
              //this.date = {}
              
              console.log('success')
              this.registerForm.reset();
              //this.registerForm.controls
            }) 
        }
        this.succ = true;

        //alert("تم اضافة استشارتك بنجاح سيتم التواصل معك من خلال مستشارينا القانونين قريبا ")
    }

    onSubmit1() {
      this.submitted = true;
      //email: new FormControl();
        //this.date = new Date(this.date).valueOf();       
      // stop here if form is invalid
      if (this.registerForm1.invalid) {
        this.err = true;
         return;
            }else{
        var k = this.db.list('Contacts').push({});
        k.set(this.registerForm1.value)
          .then(_ => {
            //this.date = {}
            console.log('success')
            this.registerForm1.reset();
            
          }) 
      }
      //alert("تم اضافة استشارتك بنجاح  سيتم التواصل معك من خلال مستشارينا القانونين قريبا ")
      this.succ = true;

      //alert('SUCCESS!! :-)\n\n');// + JSON.stringify(this.registerForm1.value))
    }

    
    dologin(value){
      //alert("sd");
      this.submitted = true;
      if (this.registerFormLogin.invalid) {
        this.errlogin = true;
        alert("لطفاً أدخل بيانات صحيحة");
          return;
      }else{
        //alert(value.email1 + "_"+ value.password1);
       return new Promise<any>((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(value.email1, value.password1)
        .then(res => {
          resolve(res);

            console.log('success')
            this.registerFormLogin.reset();
            localStorage.setItem("UserKey", res.user.uid);
            this.db.list('Users/'+res.user.uid).valueChanges().subscribe(t=>{
              
              localStorage.setItem("UserName", t[5].toString());
            });
            
            // alert("تم الدخول بنجاح شكرا لك");
           window.location.href = "home";

        }, err => {
          alert("  خطأ في إسم المستخدم أو كلمة المرور");
          this.errlogin = true;
          reject(err)})
      })
   
     // this.succlogin = true;
     // return;
    }
  
  } 
  
  doUpdate(value){
    this.submitted = true;
    //alert("sd"+ value.fname + "_" + value.fphone);
    //
    if (this.registerFormUpdate.invalid) {
      this.errreg = true;
      //alert("لطفاً أدخل بيانات صحيحة");
        return;
    }else{
      var uid = localStorage.getItem("UserKey");
      this.db.list('Users').update(uid,{
        name: value.fname,
        phone: value.fphone.toString()              
      }) 
     this.succreg = true;
     alert("تم تحديث البيانات بنجاح")
     window.location.href = "home";
     //document.getElementById("modal_Profile").click();
     return;
   }

}

doforget(value) {
  var auth = firebase.auth();
  //alert(value.frgemail);
  auth.sendPasswordResetEmail(value.frgemail)
  .then(function() {
    // Update successful.
    alert("تم الإرسال إلى بريدك الإلكتروني");
    window.location.href = "home";

  }).catch(function(error) {
    alert("خطأ في البريد الإلكتروني")
  });
    //firebase.auth().
}

doChange(value){
  if (this.registerFormChange.invalid) {
    alert("لطفاً أدخل بيانات صحيحة");
      return;
  }else{
  const user = auth().currentUser;
//alert(user.uid);
//console.log("user : "+user);
 if(value.newpassword == value.repassword){
  user.updatePassword(value.newpassword).then(function() {
    // Update successful.
    alert("تم نغيير كلمة المرور بنجاح");
    window.location.href = "home";

  }).catch(function(error) {
//alert(error);
    // An error happened.
    alert("خطأ في كلمة المرور حاول مرة أَْخرى")
  });
}else{
  alert("كلمتا المرور غير متطابقتان")
}
  }
}
    doRegister(value){
      //alert("sd");
      this.submitted = true;
      if (this.registerFormRegister.invalid) {
        this.errreg = true;
        alert("لطفاً أدخل بيانات صحيحة");
          return;
      }else{
        if(value.password == value.repassword){
       // alert(value.email + "_"+ value.password);
       new Promise<any>((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          //alert("yes");
          resolve(res);
        var uid = res.user.uid;
        this.db.list('Users').set(uid,{
          name: value.name,
          email: value.email,
          phone: value.phone.toString(),
          userType : 0,
          userId:uid,
          userImage:'',
          DOB:'',
          fcmToken:'',
          gender:'',
          os:'web',
          userStatus:0,
          createdAt:new Date().getTime()              
        })
          .then(_ => {
            //this.date = {}
            console.log('success')
            this.registerFormRegister.reset();
            alert("تم التسجيل بنجاح شكرا لك");
          })
        }, err => {
          //alert("er");
          this.errreg = true;
          reject(err)})
      })
    }else{
      alert("يجب تطابق كلمتا المرور"); 
    }
      this.succreg = true;
      return;
    }
  
  } 
}