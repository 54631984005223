import { Component,OnInit } from '@angular/core';
import { Injectable, ViewChildren } from '@angular/core';
import {  ViewChild, OnDestroy } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
// import { Subscription, Observable } from 'rxjs';
// import { MatPaginator, MatSort,MatInput,MatFormField,MatFormFieldControl } from '@angular/material';
import { Validators, FormGroup, FormBuilder,FormControl,ReactiveFormsModule,FormsModule } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {CarouselModule} from 'primeng/carousel';
import { auth } from 'firebase';
import * as firebase from 'firebase';
  
// import {*} from 'firebase';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

    //route: any;
    title : any;
    err : boolean = false;
    succ : boolean = false;
    errrep : boolean = false;
    succrep : boolean = false;
    err1 : boolean = false;
    succ1 : boolean = false;
    IsLogin:boolean=false;
    IsFav:boolean=false;
    postTitle : any;
    postImage : any;
    postDesc : any;
    postDate : any;
    Homename:any;
    RelatedPosts : any[];
    RelatedPosts2: any[];
    registerFormreport:FormGroup;
    registerForm4: FormGroup;
    registerForm3: FormGroup;
    filterForm:FormGroup;
    ContactCount : any;
    CategoriesCount : any;
    submitted = false;
    date = new Date();
    slides: any = [[]];
    registerForm: FormGroup;
    registerFormUpdate:FormGroup;
    registerForm1: FormGroup;
    registerFormforget:FormGroup;
    registerFormLogin:FormGroup;
    registerFormRegister:FormGroup;
    registerFormChange:FormGroup;
    errreg:boolean=false;
    succreg : boolean = false;
    succlogin:boolean=false;
    errlogin:boolean=false;
    emm :any;
    image : any;
    phone:any;
    chunk(arr, chunkSize) {
      let R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        R.push(arr.slice(i, i + chunkSize));
      }
      return R;
    }
   
    slideConfig = {"slidesToShow": 4, "slidesToScroll": 4};

    constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private db: AngularFireDatabase) { 
      
     }
  // ...
  ngOnInit() {
    const cid = this.route.snapshot.params.cid;
    const id = this.route.snapshot.params.id;

    if (localStorage.getItem("UserKey") != null)
    {
  
   this.db.list('Users/'+localStorage.getItem("UserKey")).valueChanges().subscribe(t=>{
              
    localStorage.setItem("UserName", t[5].toString());
    this.Homename = localStorage.getItem("UserName");

    this.phone = t[7];
    this.emm = t[2];
  });
  this.IsLogin = true;

    } else
    {
      this.IsLogin = false;
    }


    if (localStorage.getItem("UserKey") != null)
    {
   this.IsLogin = true;
   this.db.list('Users/'+localStorage.getItem("UserKey")).valueChanges().subscribe(t=>{
              
    localStorage.setItem("UserName", t[5].toString());
  });
   this.Homename = localStorage.getItem("UserName");
   this.db.list('Favorite/'+localStorage.getItem("UserKey")).valueChanges().subscribe(t=>{
    t.forEach(e => {
      if(e == id){
        this.IsFav = true;
      }
      //console.log("yyy111 ",e);
    });
  });

    } else
    {
      this.IsLogin = false;
    }
   
    //this.books =  
  //console.log(this.db.list('/Categories').valueChanges().subscribe(d=>{
        //this.dataSource.data = d;
       // this.cat = this.db.list('/Categories');//this.getCategories();
        this.db.list('posts/'+cid +"/"+id,ref => ref.orderByChild("title")).valueChanges().subscribe(d=>{
        this.postTitle = d[7];  
        this.postDesc = d[2];
        this.postImage = d[4];
        this.postDate = d[1];
        console.log("ff ",d);
  
      });

      // this.db.list('AllPosts',ref => ref.orderByChild("catId").equalTo(cid).limitToLast(4)).valueChanges().subscribe(d=>{
      //   this.RelatedPosts = d;  
      //   console.log("ffjjj ",d);
      //   //this.slides = this.chunk(this.RelatedPosts, 3);

      // });

      var r2 = [];
      var r1 = [];

      this.db.list('AllPosts',ref => ref.orderByChild("catId").equalTo(cid)).stateChanges().subscribe(d2=>{
      
       if(d2.key != id && r2.length < 4){
        r2.push({v : d2.payload.toJSON(),k : d2.key});
       }else{

       }
          // console.log(snapshot, snapshot.payload.val());
            //this.article = snapshot;
            //console.log(d2.key,d2.payload.toJSON());
        //});
         //this.article = d2.payload.toJSON();                          
        
      });
      //console.log("ff ",r2);
      this.RelatedPosts = r2;
      if(r2.length == 0){
        this.db.list('AllPosts',ref =>ref.orderByChild("subCatId").equalTo(cid)).stateChanges().subscribe(d2=>{
      
          if(d2.key != id && r1.length < 4){
           r1.push({v : d2.payload.toJSON(),k : d2.key});
          }else{
   
          }
             // console.log(snapshot, snapshot.payload.val());
               //this.article = snapshot;
               //console.log(d2.key,d2.payload.toJSON());
           //});
            //this.article = d2.payload.toJSON();                          
           
         });
         this.RelatedPosts2 = r1;

      }
      this.db.list('posts',ref => ref.orderByChild("date").limitToLast(2)).valueChanges().subscribe(d=>{
        d.forEach(e => {
          //console.log("yyy111 ",e);
        });
        //console.log("ffwww111 ",d);
  
      });
      
      // var ref = firebase.app().database().ref();
      // var peopleRef = ref.child('posts');
      // var peopleUrl = peopleRef.toString() + '.json?shallow=true';
      // console.log(peopleUrl);
  
      this.db.list('Contacts').valueChanges().subscribe(d=>{
        this.ContactCount = d.length;  
        //console.log("ff ",d);
  
      });

      this.filterForm= this.formBuilder.group({
        filter: ['', Validators.required]
      }, {
        // validator: MustMatch('password', 'confirmPassword')
      });

      this.db.list('Categories/'+cid).valueChanges().subscribe(t=>{
        if(t.length != 0){
        this.title = t[7];
        //console.log("ffh ",t);
        }else{
          this.db.list('posts/'+cid+"/"+id).valueChanges().subscribe(t=>{
            this.title = t[7];
          });
        }
     });
  

      this.db.list('Categories',ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(d=>{
        this.CategoriesCount = d.length;  
        //console.log("ff ",d);
      });

      this.registerFormreport = this.formBuilder.group({
        text: ['', Validators.required],
        userId:localStorage.getItem("UserKey"),
        date:new Date().getTime()
  
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });

      this.registerForm3 = this.formBuilder.group({
        fullname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        content: ['', Validators.required],
        phone: [''],
        type : 3,
        Status:false,
        date:new Date().getTime()
  
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
  
    this.registerForm4 = this.formBuilder.group({
      fullname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        content: ['', Validators.required],
        phone: [''],
        type : 1,
        Status:false,
        date:new Date().getTime()
  }, {
      // validator: MustMatch('password', 'confirmPassword')
  }); 
    
   
  this.registerForm = this.formBuilder.group({
    fullname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    content: ['', Validators.required],
    phone: [''],
    type : 3,
    Status:false,
    date:new Date().getTime()

}, {
    // validator: MustMatch('password', 'confirmPassword')
});

this.registerForm1 = this.formBuilder.group({
  fullname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    content: ['', Validators.required],
    phone: [''],
    type : 1,
    Status:false,
    date:new Date().getTime()
}, {
  // validator: MustMatch('password', 'confirmPassword')
});

this.registerFormRegister = this.formBuilder.group({
  name: ['', Validators.required],
  email: ['', [Validators.required, Validators.email]],
  phone: ['', Validators.required],
  userType : 0,
  userId:'',
  userImage:'',
  DOB:'',
  fcmToken:'',
  password:['', Validators.required],
  repassword:['', Validators.required],
  gender:'',
  os:'web',
  userStatus:0,
  createdAt:new Date().getTime()
}, {
// validator: MustMatch('password', 'confirmPassword')
});
this.registerFormLogin = this.formBuilder.group({
email1: ['', [Validators.required, Validators.email]],
password1: ['', Validators.required],

}, {
// validator: MustMatch('password', 'confirmPassword')
});

this.registerFormChange = this.formBuilder.group({
oldpassword: ['', [Validators.required]],
newpassword: ['', Validators.required],
repassword: ['', Validators.required]

}, {
// validator: MustMatch('password', 'confirmPassword')
});

this.registerFormUpdate = this.formBuilder.group({
fname: ['', [Validators.required]],
fphone: ['', Validators.required],
}, {
// validator: MustMatch('password', 'confirmPassword')
});


this.registerFormforget = this.formBuilder.group({
frgemail: ['', [Validators.required]]
}, {
// validator: MustMatch('password', 'confirmPassword')
});
}


  close(){
    //alert("");
    this.err = false;

    return;
  }

  red(){
    //alert(filter);
    var filter = this.filterForm.getRawValue().filter;
    if(filter != '' && filter != undefined && filter != ' '&& filter != null){
    window.location.href = "/search/"+filter;
    }else{
      return ;
    }
  }
  close2(){
    //alert("");
    this.succ = false;

    return;
  }
  close11(){
    //alert("");
    this.err1 = false;

    return;
  }

  
  closerep(){
    //alert("");
    this.errrep = false;

    return;
  }

closesuccrep(){
    //alert("");
    this.succrep = false;

    return;
  }
close22(){
    //alert("");
    this.succ1 = false;

    return;
}

logout(){
  var r = confirm("هل تريد تسجيل الخروج ؟");
  if (r == true) {
  auth().signOut().then(() => {
  })

 localStorage.clear();
 window.location.href = "home";
}else{
  
}
}



refrsh(){
  window.location.reload();
  //return ;
}


onSubmitReport(value) {
    this.submitted = true;

    //alert("sw");
    //email: new FormControl();
      //this.date = new Date(this.date).valueOf();       
    // stop here if form is invalid
    if (this.registerFormreport.invalid) {
      this.errrep = true;
      alert("لطفاً أدخل بيانات صحيحة");
        return;
    }else{
      const id = this.route.snapshot.params.id;
      this.db.list('Reports/'+id).push({text: value.text,
      userId:localStorage.getItem("UserKey"),
      date:new Date().getTime()})
        .then(_ => {
          //this.date = {}
          
          console.log('success')
          this.registerFormreport.reset();
          alert("تم إرسال إبلاغك");
          //this.registerForm.controls
          document.getElementById("modalReport").click();

        }) 
    }
    this.succrep = true;
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm3.value))
}
  
fav(Favor) {
  const id = this.route.snapshot.params.id;
  if(Favor == false){
  this.submitted = true;
  //alert("sw");

    this.db.list('Favorite/'+ localStorage.getItem("UserKey")).push(id)
      .then(_ => {
        //this.date = {}
        console.log('success')
        this.registerFormreport.reset();
        alert("تم الإضافة للمفضلة بنجاح شكراً لك");
        this.IsFav = true;
        //this.registerForm.controls
      }) 
  
  this.succrep = true;
  //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm3.value))
    }else{
      var r = confirm("هل تريد أن تحذف من المفضلة ؟");
      if (r == true) {
         this.db.list('Favorite/'+localStorage.getItem("UserKey"),ref => ref.orderByValue().equalTo(id)).stateChanges().subscribe(t=>{       
           if(t.payload.val() == id){
          this.db.list('Favorite/'+localStorage.getItem("UserKey")+"/"+t.key).remove()
          
         }
             });
         alert("تم الحذف من المفضلة بنجاح");
         this.IsFav = false;
         //window.location.href = "favorite";
           }else{
             
           }
       
    }
}
onSubmit3(value) {
    this.submitted = true;
    //alert(value.email);
    //email: new FormControl();
      //this.date = new Date(this.date).valueOf();       
    // stop here if form is invalid
    if(!this.IsLogin){
    if (this.registerForm3.invalid) {
      this.err1 = true;
        return;
    }else{
      var k = this.db.list('Contacts').push({});
      k.set(this.registerForm3.value)
        .then(_ => {
          //this.date = {}
          
          console.log('success')
          this.registerForm3.reset();
          //this.registerForm.controls
        }) 
    }
    this.succ = true;
    //alert("تم اضافة استشارتك بنجاح سيتم التواصل معك من خلال مستشارينا القانونين قريبا");
    }else{
    if(value.content == ''){
      this.err1 = true;
        return;
    }else{
      var k = this.db.list('Contacts').push({});
      k.set({
        fullname: this.Homename,
        email: this.emm,
        content: value.content,
        phone: value.phone,
        type : 3,
        Status:false,
        date:new Date().getTime()
        
      })
        .then(_ => {
          //this.date = {}
          
          console.log('success')
          this.registerForm3.reset();
          //this.registerForm.controls
        }) 
        this.succ1 = true;
        //alert("تم اضافة استشارتك بنجاح سيتم التواصل معك من خلال مستشارينا القانونين قريبا");
      
    }
  }
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm3.value))
}
onSubmit4(value) {
  this.submitted = true;
  //email: new FormControl();
    //this.date = new Date(this.date).valueOf();       
  // stop here if form is invalid
  if(!this.IsLogin){
    if (this.registerForm4.invalid) {
      this.err1 = true;
        return;
    }else{
      var k = this.db.list('Contacts').push({});
      k.set(this.registerForm4.value)
        .then(_ => {
          //this.date = {}
          
          console.log('success')
          this.registerForm4.reset();
          //this.registerForm.controls
        }) 
    }
    this.succ = true;
    //alert("تم اضافة استشارتك بنجاح سيتم التواصل معك من خلال مستشارينا القانونين قريبا");
    }else{
    if(value.content == ''){
      this.err = true;
        return;
    }else{
      var k = this.db.list('Contacts').push({});
      k.set({
        fullname: this.Homename,
        email: this.emm,
        content: value.content,
        phone: value.phone,
        type : 1,
        Status:false,
        date:new Date().getTime()
        
      })
        .then(_ => {
          //this.date = {}
          
          console.log('success')
          this.registerForm3.reset();
          //this.registerForm.controls
        }) 
        this.succ = true;
        //alert("تم اضافة استشارتك بنجاح سيتم التواصل معك من خلال مستشارينا القانونين قريبا");
      
    }
  }
  // if (this.registerForm4.invalid) {
  //   this.err = true;
  //   alert("لطفاً أدخل بيانات صحيحة");
  //     return;
  // }else{
  //   var k = this.db.list('Contacts').push({});
  //   k.set(this.registerForm4.value)
  //     .then(_ => {
  //       //this.date = {}
  //       console.log('success')
  //       this.registerForm4.reset();
        
  //     }) 
  // }
  // this.succ = true;
  // alert("تم اضافة استشارتك بنجاح سيتم التواصل معك من خلال مستشارينا القانونين قريبا");
  //alert('SUCCESS!! :-)\n\n');// + JSON.stringify(this.registerForm1.value))
}

dologin(value){
  //alert("sd");
  this.submitted = true;
  if (this.registerFormLogin.invalid) {
    this.errlogin = true;
    alert("لطفاً أدخل بيانات صحيحة");
      return;
  }else{
    //alert(value.email1 + "_"+ value.password1);
   return new Promise<any>((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(value.email1, value.password1)
    .then(res => {
      resolve(res);

        console.log('success')
        this.registerFormLogin.reset();
        localStorage.setItem("UserKey", res.user.uid);
        this.db.list('Users/'+res.user.uid).valueChanges().subscribe(t=>{
          
          localStorage.setItem("UserName", t[5].toString());
        });
        
        // alert("تم الدخول بنجاح شكرا لك");
       window.location.href = "home";

    }, err => {
      alert("  خطأ في إسم المستخدم أو كلمة المرور");
      this.errlogin = true;
      reject(err)})
  })

 // this.succlogin = true;
 // return;
}

} 

doUpdate(value){
this.submitted = true;
//alert("sd"+ value.fname + "_" + value.fphone);
//
if (this.registerFormUpdate.invalid) {
  this.errreg = true;
  //alert("لطفاً أدخل بيانات صحيحة");
    return;
}else{
  var uid = localStorage.getItem("UserKey");
  this.db.list('Users').update(uid,{
    name: value.fname,
    phone: value.fphone.toString()              
  })  
 this.succreg = true;
 alert("تم تحديث البيانات بنجاح")
 window.location.href = "home";
 //document.getElementById("modal_Profile").click();
 return;
}

}

doforget(value) {
var auth = firebase.auth();
//alert(value.frgemail);
auth.sendPasswordResetEmail(value.frgemail)
.then(function() {
// Update successful.
alert("تم الإرسال إلى بريدك الإلكتروني");
window.location.href = "home";

}).catch(function(error) {
alert("خطأ في البريد الإلكتروني")
});
//firebase.auth().
}

doChange(value){
if (this.registerFormChange.invalid) {
alert("لطفاً أدخل بيانات صحيحة");
  return;
}else{
const user = auth().currentUser;
//alert(user.uid);
//console.log("user : "+user);
if(value.newpassword == value.repassword){
user.updatePassword(value.newpassword).then(function() {
// Update successful.
alert("تم نغيير كلمة المرور بنجاح");
window.location.href = "home";

}).catch(function(error) {
//alert(error);
// An error happened.
alert("خطأ في كلمة المرور حاول مرة أَْخرى")
});
}else{
alert("كلمتا المرور غير متطابقتان")
}
}
}
doRegister(value){
  //alert("sd");
  this.submitted = true;
  if (this.registerFormRegister.invalid) {
    this.errreg = true;
    alert("لطفاً أدخل بيانات صحيحة");
      return;
  }else{
    if(value.password == value.repassword){
   // alert(value.email + "_"+ value.password);
   new Promise<any>((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
    .then(res => {
      //alert("yes");
      resolve(res);
    var uid = res.user.uid;
    this.db.list('Users').set(uid,{
      name: value.name,
      email: value.email,
      phone: value.phone.toString(),
      userType : 0,
      userId:uid,
      userImage:'',
      DOB:'',
      fcmToken:'',
      gender:'',
      os:'web',
      userStatus:0,
      createdAt:new Date().getTime()              
    })
      .then(_ => {
        //this.date = {}
        console.log('success')
        this.registerFormRegister.reset();
        alert("تم التسجيل بنجاح شكرا لك");
      })
    }, err => {
      //alert("er");
      this.errreg = true;
      reject(err)})
  })
}else{
  alert("يجب تطابق كلمتا المرور"); 
}
  this.succreg = true;
  return;
}

} 

}

