  import { Component,OnInit } from '@angular/core';
  import { Injectable, ViewChildren } from '@angular/core';
  import {  ViewChild, OnDestroy } from '@angular/core';
  import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
  // import { Subscription, Observable } from 'rxjs';
  // import { MatPaginator, MatSort,MatInput,MatFormField,MatFormFieldControl } from '@angular/material';
  import { Validators, FormGroup, FormBuilder,FormControl,ReactiveFormsModule,FormsModule } from '@angular/forms';
  import { forEach } from '@angular/router/src/utils/collection';
  
  import { ActivatedRoute } from '@angular/router';
  import { Location } from '@angular/common';
  import { filter } from 'rxjs/operators';
  import { Ng2SearchPipeModule } from 'ng2-search-filter'; //importing the module
  import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
  import {NgxPaginationModule} from 'ngx-pagination';
  
  @Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
  })
  export class SearchComponent implements OnInit {
  
    article : any=[];
    cat : any=[];
    title : any;
    q : any = "";
    filter : any;
    filter2 : any;
    show: boolean = false;
    hide: boolean = false;
    subcat : any=[];
    key: string = 'title'; //set default
    reverse: boolean = false;
    sort(key){
      this.key = key;
      this.reverse = !this.reverse;
    }
  
    //initializing p to one
    p: number = 1;
  
    constructor(private route: ActivatedRoute, private db: AngularFireDatabase ) { }
  
    ngOnInit(): void {
      const q = this.route.snapshot.params.q;
      this.q = q;
      this.filter = q;
      this.filter2 = q;


      document.getElementById("f").textContent = q;
        this.db.list('Categories',ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(d=>{
        this.cat = d;  
        console.log("ff ",d);
        if(d.length > 0){
          this.show = true;
        }else{
          this.hide = true;
        }
  
      });
  
      this.db.list('AllPosts').valueChanges().subscribe(t=>{
          this.article = t;
          console.log("ffww ",t);
       });
          
      var r2 = [];
      this.db.list('AllPosts').stateChanges().subscribe(d2=>{
      
       //if(d2.payload.child("subCatId").val() == ""){
        r2.push({v : d2.payload.toJSON(),k : d2.key});
       //}
          // console.log(snapshot, snapshot.payload.val());
            //this.article = snapshot;
            //console.log("qwr : ",d2.key,d2.payload.toJSON());
        //});
         //this.article = d2.payload.toJSON();                          
        
      });
      //console.log("ffww ",r2);
      //this.article = r2;

    
     
    }

  
  }  

