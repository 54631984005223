  import { Component,OnInit } from '@angular/core';
  import { Injectable, ViewChildren } from '@angular/core';
  import {  ViewChild, OnDestroy } from '@angular/core';
  import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
  // import { Subscription, Observable } from 'rxjs';
  // import { MatPaginator, MatSort,MatInput,MatFormField,MatFormFieldControl } from '@angular/material';
  import { Validators, FormGroup, FormBuilder,FormControl,ReactiveFormsModule,FormsModule } from '@angular/forms';
  import { forEach } from '@angular/router/src/utils/collection';
  
  import { ActivatedRoute } from '@angular/router';
  import { Location } from '@angular/common';
  import { auth } from 'firebase';
import * as firebase from 'firebase';
  
  @Component({
    selector: 'app-subcat',
    templateUrl: './subcat.component.html',
    styleUrls: ['./subcat.component.css']
  })
  export class SubcatComponent implements OnInit {
  
   //route: any;
   title : any;
   postTitle : any;
   postImage : any;
   postDesc : any;
   postDate : any;
   RelatedPosts : any[];
   registerForm4: FormGroup;
   registerForm3: FormGroup;
   ContactCount : any;
   filterForm:FormGroup;
   CategoriesCount : any;
   submitted = false;
   date = new Date();
   slides: any = [[]];
   errreg:boolean=false;
   IsLogin:boolean=false;
   succ : boolean = false;
   succreg : boolean = false;
   succlogin:boolean=false;
   errlogin:boolean=false;
   IsmodelShow:boolean=false;
   IsmodelShowlog:boolean=false;
   post : any[];
   msg : any;
   type:any;
   dis:any;
   lastPosts : any[]; //Observable<any>;
   registerForm: FormGroup;
   registerFormUpdate:FormGroup;
   registerForm1: FormGroup;
   registerFormforget:FormGroup;
   registerFormLogin:FormGroup;
   registerFormRegister:FormGroup;
   registerFormChange:FormGroup;
   emm :any;
   image : any;
   Homename:any;
   phone:any;
   chunk(arr, chunkSize) {
     let R = [];
     for (let i = 0, len = arr.length; i < len; i += chunkSize) {
       R.push(arr.slice(i, i + chunkSize));
     }
     return R;
   }
   key: string = 'name'; //set default
   reverse: boolean = false;
   sort(key){
     this.key = key;
     this.reverse = !this.reverse;
   }
 
   //initializing p to one
   p: number = 1;
  
   slideConfig = {"slidesToShow": 4, "slidesToScroll": 4};

   constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private db: AngularFireDatabase) { 
     
    }
 // ...
 ngOnInit() {
   const cid = this.route.snapshot.params.cid;
   const id = this.route.snapshot.params.id;

   if (localStorage.getItem("UserKey") != null)
   {
 
  this.db.list('Users/'+localStorage.getItem("UserKey")).valueChanges().subscribe(t=>{
             
   localStorage.setItem("UserName", t[5].toString());
   this.Homename = localStorage.getItem("UserName");

   this.phone = t[7];
   this.emm = t[2];
 });
 this.IsLogin = true;

   } else
   {
     this.IsLogin = false;
   }
   //this.books =  
 //console.log(this.db.list('/Categories').valueChanges().subscribe(d=>{
       //this.dataSource.data = d;
      // this.cat = this.db.list('/Categories');//this.getCategories();
       this.db.list('posts/'+cid +"/"+id,ref => ref.orderByChild("title")).valueChanges().subscribe(d=>{
       this.postTitle = d[6];  
       this.postDesc = d[2];
       this.postImage = d[3];
       this.postDate = d[1];
       //console.log("ff ",this.postDate);
 
     });

     this.db.list('AllPosts',ref => ref.orderByChild("catId").equalTo(cid)).valueChanges().subscribe(d=>{
       this.RelatedPosts = d;  
       console.log("ffjjj ",d);
       this.slides = this.chunk(this.RelatedPosts, 3);

     });
 
     this.db.list('posts',ref => ref.orderByChild("date").limitToLast(2)).valueChanges().subscribe(d=>{
       d.forEach(e => {
         console.log("yyy111 ",e);
       });
       console.log("ffwww111 ",d);
 
     });
     
     // var ref = firebase.app().database().ref();
     // var peopleRef = ref.child('posts');
     // var peopleUrl = peopleRef.toString() + '.json?shallow=true';
     // console.log(peopleUrl);
 
     this.db.list('Contacts').valueChanges().subscribe(d=>{
       this.ContactCount = d.length;  
       //console.log("ff ",d);
 
     });


     this.db.list('Categories/'+id,ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(t=>{
       this.title = t[6];
         
    });
 

     this.db.list('Categories',ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(d=>{
       this.CategoriesCount = d.length;  
       //console.log("ff ",d);
     });


     this.registerForm3 = this.formBuilder.group({
       fullname: ['', Validators.required],
       email: ['', [Validators.required, Validators.email]],
       content: ['', Validators.required],
       phone: [''],
       type : 3,
       Status:false,
       date:new Date().getTime()
 
   }, {
       // validator: MustMatch('password', 'confirmPassword')
   });
 
   this.registerForm4 = this.formBuilder.group({
     fullname: ['', Validators.required],
       email: ['', [Validators.required, Validators.email]],
       content: ['', Validators.required],
       phone: [''],
       type : 1,
       Status:false,
       date:new Date().getTime()
 }, {
     // validator: MustMatch('password', 'confirmPassword')
 }); 
   
 this.filterForm= this.formBuilder.group({
  filter: ['', Validators.required]
}, {
  // validator: MustMatch('password', 'confirmPassword')
});

this.registerForm = this.formBuilder.group({
  fullname: ['', Validators.required],
  email: ['', [Validators.required, Validators.email]],
  content: ['', Validators.required],
  phone: [''],
  type : 3,
  Status:false,
  date:new Date().getTime()

}, {
  // validator: MustMatch('password', 'confirmPassword')
});

this.registerForm1 = this.formBuilder.group({
fullname: ['', Validators.required],
  email: ['', [Validators.required, Validators.email]],
  content: ['', Validators.required],
  phone: [''],
  type : 1,
  Status:false,
  date:new Date().getTime()
}, {
// validator: MustMatch('password', 'confirmPassword')
});

this.registerFormRegister = this.formBuilder.group({
name: ['', Validators.required],
email: ['', [Validators.required, Validators.email]],
phone: ['', Validators.required],
userType : 0,
userId:'',
userImage:'',
DOB:'',
fcmToken:'',
password:['', Validators.required],
repassword:['', Validators.required],
gender:'',
os:'web',
userStatus:0,
createdAt:new Date().getTime()
}, {
// validator: MustMatch('password', 'confirmPassword')
});
this.registerFormLogin = this.formBuilder.group({
email1: ['', [Validators.required, Validators.email]],
password1: ['', Validators.required],

}, {
// validator: MustMatch('password', 'confirmPassword')
});

this.registerFormChange = this.formBuilder.group({
oldpassword: ['', [Validators.required]],
newpassword: ['', Validators.required],
repassword: ['', Validators.required]

}, {
// validator: MustMatch('password', 'confirmPassword')
});

this.registerFormUpdate = this.formBuilder.group({
fname: ['', [Validators.required]],
fphone: ['', Validators.required],
}, {
// validator: MustMatch('password', 'confirmPassword')
});


this.registerFormforget = this.formBuilder.group({
frgemail: ['', [Validators.required]]
}, {
// validator: MustMatch('password', 'confirmPassword')
});
}

close(){
//alert("");
this.errreg = false;

return;
}

close2(){
//alert("");
this.succ = false;

return;
}
chng(){
document.getElementById("modal_Profile").click();
}
close11(){
//alert("");
this.errlogin = false;

return;
}

closelogin(){
//alert("");
this.succlogin = false;

return;
}
closereg(){
//alert("");
this.errreg = false;

return;
}
cl(){
this.IsmodelShow=true;
}
fr(){
//this.IsmodelShowlog=true;
document.getElementById("modal_login").click();

}
closesucc(){
//alert("");
this.succreg = false;

return;
}
logout(){
var r = confirm("هل تريد تسجيل الخروج ؟");
if (r == true) {
auth().signOut().then(() => {
})

localStorage.clear();
window.location.href = "home";
}else{

}
}



dologin(value){
//alert("sd");
this.submitted = true;
if (this.registerFormLogin.invalid) {
  this.errlogin = true;
  alert("لطفاً أدخل بيانات صحيحة");
    return;
}else{
  //alert(value.email1 + "_"+ value.password1);
 return new Promise<any>((resolve, reject) => {
  firebase.auth().signInWithEmailAndPassword(value.email1, value.password1)
  .then(res => {
    resolve(res);

      console.log('success')
      this.registerFormLogin.reset();
      localStorage.setItem("UserKey", res.user.uid);
      this.db.list('Users/'+res.user.uid).valueChanges().subscribe(t=>{
        
        localStorage.setItem("UserName", t[5].toString());
      });
      
      // alert("تم الدخول بنجاح شكرا لك");
     window.location.href = "home";

  }, err => {
    alert("  خطأ في إسم المستخدم أو كلمة المرور");
    this.errlogin = true;
    reject(err)})
})

// this.succlogin = true;
// return;
}

} 

doUpdate(value){
this.submitted = true;
//alert("sd"+ value.fname + "_" + value.fphone);
//
if (this.registerFormUpdate.invalid) {
this.errreg = true;
//alert("لطفاً أدخل بيانات صحيحة");
  return;
}else{
var uid = localStorage.getItem("UserKey");
this.db.list('Users').update(uid,{
  name: value.fname,
  phone: value.fphone.toString()              
})  
this.succreg = true;
alert("تم تحديث البيانات بنجاح")
window.location.href = "home";
//document.getElementById("modal_Profile").click();
return;
}

}

doforget(value) {
var auth = firebase.auth();
//alert(value.frgemail);
auth.sendPasswordResetEmail(value.frgemail)
.then(function() {
// Update successful.
alert("تم الإرسال إلى بريدك الإلكتروني");
window.location.href = "home";

}).catch(function(error) {
alert("خطأ في البريد الإلكتروني")
});
//firebase.auth().
}

doChange(value){
if (this.registerFormChange.invalid) {
alert("لطفاً أدخل بيانات صحيحة");
return;
}else{
const user = auth().currentUser;
//alert(user.uid);
//console.log("user : "+user);
if(value.newpassword == value.repassword){
user.updatePassword(value.newpassword).then(function() {
// Update successful.
alert("تم نغيير كلمة المرور بنجاح");
window.location.href = "home";

}).catch(function(error) {
//alert(error);
// An error happened.
alert("خطأ في كلمة المرور حاول مرة أَْخرى")
});
}else{
alert("كلمتا المرور غير متطابقتان")
}
}
}
doRegister(value){
//alert("sd");
this.submitted = true;
if (this.registerFormRegister.invalid) {
  this.errreg = true;
  alert("لطفاً أدخل بيانات صحيحة");
    return;
}else{
  if(value.password == value.repassword){
 // alert(value.email + "_"+ value.password);
 new Promise<any>((resolve, reject) => {
  firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
  .then(res => {
    //alert("yes");
    resolve(res);
  var uid = res.user.uid;
  this.db.list('Users').set(uid,{
    name: value.name,
    email: value.email,
    phone: value.phone.toString(),
    userType : 0,
    userId:uid,
    userImage:'',
    DOB:'',
    fcmToken:'',
    gender:'',
    os:'web',
    userStatus:0,
    createdAt:new Date().getTime()              
  })
    .then(_ => {
      //this.date = {}
      console.log('success')
      this.registerFormRegister.reset();
      alert("تم التسجيل بنجاح شكرا لك");
    })
  }, err => {
    //alert("er");
    this.errreg = true;
    reject(err)})
})
}else{
alert("يجب تطابق كلمتا المرور"); 
}
this.succreg = true;
return;
}

} 

 
 
 
 onSubmit3() {
   this.submitted = true;
   //email: new FormControl();
     //this.date = new Date(this.date).valueOf();       
   // stop here if form is invalid
   if (this.registerForm3.invalid) {
       return;
   }else{
     var k = this.db.list('Contacts').push({});
     k.set(this.registerForm3.value)
       .then(_ => {
         //this.date = {}
         
         console.log('success')
         this.registerForm3.reset();
         //this.registerForm.controls
       }) 
   }

   alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm3.value))
}
onSubmit4() {
 this.submitted = true;
 //email: new FormControl();
   //this.date = new Date(this.date).valueOf();       
 // stop here if form is invalid
 if (this.registerForm4.invalid) {
     return;
 }else{
   var k = this.db.list('Contacts').push({});
   k.set(this.registerForm4.value)
     .then(_ => {
       //this.date = {}
       console.log('success')
       this.registerForm4.reset();
       
     }) 
 }

 alert('SUCCESS!! :-)\n\n');// + JSON.stringify(this.registerForm1.value))
}

red(){
  //alert(filter);
  var filter = this.filterForm.getRawValue().filter;
  if(filter != '' && filter != undefined && filter != ' '&& filter != null){
  window.location.href = "/search/"+filter;
  }else{
    return ;
  }
}

 }
 

