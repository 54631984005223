import { Component, OnInit } from '@angular/core';
import { Injectable, ViewChildren } from '@angular/core';
import {  ViewChild, OnDestroy } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Validators, FormGroup, FormBuilder,FormControl,ReactiveFormsModule,FormsModule } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  // export class AppComponent implements OnInit{
    
    behance : any;
    buildingNo : any;
    address: any;
    email :any;
    facebook: any;
    gmail:  any;
    instagram: any;
    linkedin: any;
    mobile: any;
    skype: any;
    websiteName:any;
    street: any;
    twitter: any;
    welcomeText:any;
    constructor(private db: AngularFireDatabase) { 
      
     }
  ngOnInit() {

    this.db.list('Settings').valueChanges().subscribe(t=>{
      console.log("sett : ",t);
        this.address = t[0];
        this.behance = t[1];
        this.buildingNo = t[2];
        this.email = t[3];
        this.facebook = t[4];
        this.gmail = t[5];
        this.instagram = t[7];
        this.linkedin = t[8];
        this.mobile = t[10];
        this.skype = t[11];
        this.street = t[12];
        this.twitter = t[13];
        this.welcomeText = t[15];
        this.websiteName =t[14];
   });
  }
  op(lk){
    window.location.href =  "http://google.com";
    }
  

}
