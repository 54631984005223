import { BrowserModule } from '@angular/platform-browser';
import { NgModule,Component } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
// import { RouterModule , Route } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { app } from 'firebase';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CarouselModule} from 'primeng/carousel';
import {slick} from 'angular-slick';
import {slick2} from 'slick-carousel';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { CategoryComponent } from './category/category.component';
import { ArticleComponent } from './article/article.component';
import {SlideshowModule} from 'ng-simple-slideshow';
import { SlickModule } from 'ngx-slick';
import { SubcatComponent } from './subcat/subcat.component';
import { SubarticleComponent } from './subarticle/subarticle.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter'; //importing the module
import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchComponent } from './search/search.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { AngularFireAuthModule } from "@angular/fire/auth";


const appRoutes: Routes = [
  // { path: 'about', component: AboutComponent },
  // { path: 'statics', component: StatisticsComponent },
  // { path: 'success', component: SuccessStoryComponent },
  // { path: 'contact', component: ContactComponent },
   { path: 'cats/:id', component: CategoryComponent },
   { path: 'Article/:cid/:id', component: ArticleComponent },
   { path: 'subcat/:id', component: SubcatComponent },
   { path: 'search/:q', component: SearchComponent },

   { path: 'subarticle/:id/:catId', component: SubarticleComponent },
   
   { path: 'favorite', component: FavoriteComponent },
   { path: 'home', component: HomeComponent },

  // {
  //   path: 'hamlat',
  //   component: HamlatComponent,
  //   data: { title: 'hamlat List' }
  // },
   { path: '', component: HomeComponent },
  // { path: '**', component: PageNotFoundComponentComponent }
];



export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCSKS44Jt3IZJtBawNKdYwRpaV3g2ESEI8",
    authDomain: "legal-consultations.firebaseapp.com",
    databaseURL: "https://legal-consultations.firebaseio.com",
    projectId: "legal-consultations",
    storageBucket: "legal-consultations.appspot.com",
    messagingSenderId: "41018254466"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    CategoryComponent,
    ArticleComponent,
    SubcatComponent,
    SubarticleComponent,
    SearchComponent,
    FavoriteComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    SlideshowModule,
    SlickModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    NgxCoolDialogsModule,
    BrowserAnimationsModule
    //CarouselModule,
    //slick
 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
