  import { Component,OnInit } from '@angular/core';
  import { Injectable, ViewChildren } from '@angular/core';
  import {  ViewChild, OnDestroy } from '@angular/core';
  import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
  // import { Subscription, Observable } from 'rxjs';
  // import { MatPaginator, MatSort,MatInput,MatFormField,MatFormFieldControl } from '@angular/material';
  import { Validators, FormGroup, FormBuilder,FormControl,ReactiveFormsModule,FormsModule } from '@angular/forms';
  import { forEach } from '@angular/router/src/utils/collection';
  
  import { ActivatedRoute } from '@angular/router';
  import { Location } from '@angular/common';
import { auth } from 'firebase';
import * as firebase from 'firebase';
import { timeInterval, timeout } from 'rxjs/operators';
  
  @Component({
    selector: 'app-subarticle',
    templateUrl: './subarticle.component.html',
    styleUrls: ['./subarticle.component.css']
  })
  export class SubarticleComponent implements OnInit {
   
    article : any=[];
    cat : any=[];
    title : any;
    id : any;
    show: boolean = false;
    hide: boolean = false;
    subcat : any=[];
    filterForm:FormGroup;
    key: string = 'name'; //set default
    reverse: boolean = false;
    errreg:boolean=false;
    IsLogin:boolean=false;
    succ : boolean = false;
    succreg : boolean = false;
    succlogin:boolean=false;
    errlogin:boolean=false;
    IsmodelShow:boolean=false;
    IsmodelShowlog:boolean=false;
    post : any[];
    msg : any;
    type:any;
    dis:any;
    lastPosts : any[]; //Observable<any>;
    ContactCount : any;
    CategoriesCount : any;
    registerForm: FormGroup;
    registerFormUpdate:FormGroup;
    registerForm1: FormGroup;
    registerFormforget:FormGroup;
    registerFormLogin:FormGroup;
    registerFormRegister:FormGroup;
    registerFormChange:FormGroup;
    submitted = false;
    emm :any;
    image : any;
    Homename:any;
    phone:any;
    sort(key){
      this.key = key;
      this.reverse = !this.reverse;
    }
  
    //initializing p to one
    p: number = 1;
   
    constructor(private formBuilder: FormBuilder,private route: ActivatedRoute, private db: AngularFireDatabase ) { }
  
    ngOnInit(): void {
     
      const id = this.route.snapshot.params.id;
      const cid = this.route.snapshot.params.catId;

      this.id = id;
      //console.log("ffss ",id);
  

      if (localStorage.getItem("UserKey") != null)
    {
  
   this.db.list('Users/'+localStorage.getItem("UserKey")).valueChanges().subscribe(t=>{
              
    localStorage.setItem("UserName", t[5].toString());
    this.Homename = localStorage.getItem("UserName");

    this.phone = t[7];
    this.emm = t[2];
  });
  this.IsLogin = true;

    } else
    {
      this.IsLogin = false;
    }
        this.db.list('SubCategories/'+cid,ref => ref.orderByChild("title")).valueChanges().subscribe(d=>{
        this.cat = d;  
       // console.log("ff ",d);
        if(d.length > 0){
          this.show = true;
        }else{
          this.hide = true;
        }
  
      });
  
        this.db.list('SubCategories/'+cid+"/"+id).valueChanges().subscribe(t=>{
          this.title = t[4];
            
       });
      
       var r = [];
      this.db.list('posts/'+id).stateChanges().subscribe(d2=>{
      
       
        r.push({v : d2.payload.toJSON(),k : d2.key});
          // console.log(snapshot, snapshot.payload.val());
            //this.article = snapshot;
            //console.log(d2.key,d2.payload.toJSON());
        //});
         //this.article = d2.payload.toJSON();                          
        
      });

    //   this.db.list('Categories/'+cid,ref => ref.orderByChild("IsActive").equalTo(1)).valueChanges().subscribe(t=>{
    //     this.title = t[6];
          
    //  });
      //console.log("ff ",r);
      this.article = r;
  
    
      var r2 = [];
      this.db.list('SubCategories/'+cid,ref => ref.orderByChild("isActive").equalTo(1)).stateChanges().subscribe(d2=>{
      
       
        r2.push({v : d2.payload.toJSON(),k : d2.key});
          // console.log(snapshot, snapshot.payload.val());
            //this.article = snapshot;
            //console.log(d2.key,d2.payload.toJSON());
        //});
         //this.article = d2.payload.toJSON();                          
        
      });
      //console.log("ff ",r2);
      this.subcat = r2;


      this.filterForm= this.formBuilder.group({
        filter: ['', Validators.required]
      }, {
        // validator: MustMatch('password', 'confirmPassword')
      });


      this.registerForm = this.formBuilder.group({
        fullname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        content: ['', Validators.required],
        phone: [''],
        type : 3,
        Status:false,
        date:new Date().getTime()
  
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
  
    this.registerForm1 = this.formBuilder.group({
      fullname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        content: ['', Validators.required],
        phone: [''],
        type : 1,
        Status:false,
        date:new Date().getTime()
  }, {
      // validator: MustMatch('password', 'confirmPassword')
  });
  
  this.registerFormRegister = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      userType : 0,
      userId:'',
      userImage:'',
      DOB:'',
      fcmToken:'',
      password:['', Validators.required],
      repassword:['', Validators.required],
      gender:'',
      os:'web',
      userStatus:0,
      createdAt:new Date().getTime()
  }, {
    // validator: MustMatch('password', 'confirmPassword')
  });
  this.registerFormLogin = this.formBuilder.group({
    email1: ['', [Validators.required, Validators.email]],
    password1: ['', Validators.required],
    
  }, {
    // validator: MustMatch('password', 'confirmPassword')
  });
  
  this.registerFormChange = this.formBuilder.group({
    oldpassword: ['', [Validators.required]],
    newpassword: ['', Validators.required],
    repassword: ['', Validators.required]
  
  }, {
    // validator: MustMatch('password', 'confirmPassword')
  });
  
  this.registerFormUpdate = this.formBuilder.group({
    fname: ['', [Validators.required]],
    fphone: ['', Validators.required],
  }, {
    // validator: MustMatch('password', 'confirmPassword')
  });
  
  
  this.registerFormforget = this.formBuilder.group({
    frgemail: ['', [Validators.required]]
  }, {
    // validator: MustMatch('password', 'confirmPassword')
  });
    }

    close(){
      //alert("");
      this.errreg = false;
  
      return;
    }
  
    close2(){
      //alert("");
      this.succ = false;
  
      return;
    }
    chng(){
      document.getElementById("modal_Profile").click();
    }
    close11(){
      //alert("");
      this.errlogin = false;
  
      return;
    }
  
    closelogin(){
      //alert("");
      this.succlogin = false;
  
      return;
    }
    closereg(){
      //alert("");
      this.errreg = false;
  
      return;
    }
    cl(){
    this.IsmodelShow=true;
    }
    fr(){
      //this.IsmodelShowlog=true;
      document.getElementById("modal_login").click();
  
    }
    closesucc(){
      //alert("");
      this.succreg = false;
  
      return;
    }
  logout(){
    var r = confirm("هل تريد تسجيل الخروج ؟");
    if (r == true) {
    auth().signOut().then(() => {
    })
  
   localStorage.clear();
   window.location.href = "home";
  }else{
    
  }
  }
  

    red(){
      //alert(filter);
      var filter = this.filterForm.getRawValue().filter;
      if(filter != '' && filter != undefined && filter != ' '&& filter != null){
      window.location.href = "/search/"+filter;
      }else{
        return ;
      }
    }


    
    dologin(value){
      //alert("sd");
      this.submitted = true;
      if (this.registerFormLogin.invalid) {
        this.errlogin = true;
        alert("لطفاً أدخل بيانات صحيحة");
          return;
      }else{
        //alert(value.email1 + "_"+ value.password1);
       return new Promise<any>((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(value.email1, value.password1)
        .then(res => {
          resolve(res);

            console.log('success')
            this.registerFormLogin.reset();
            localStorage.setItem("UserKey", res.user.uid);
            this.db.list('Users/'+res.user.uid).valueChanges().subscribe(t=>{
              
              localStorage.setItem("UserName", t[5].toString());
            });
            
            // alert("تم الدخول بنجاح شكرا لك");
           window.location.href = "home";

        }, err => {
          alert("  خطأ في إسم المستخدم أو كلمة المرور");
          this.errlogin = true;
          reject(err)})
      })
   
     // this.succlogin = true;
     // return;
    }
  
  } 
  
  doUpdate(value){
    this.submitted = true;
    //alert("sd"+ value.fname + "_" + value.fphone);
    //
    if (this.registerFormUpdate.invalid) {
      this.errreg = true;
      //alert("لطفاً أدخل بيانات صحيحة");
        return;
    }else{
      var uid = localStorage.getItem("UserKey");
      this.db.list('Users').update(uid,{
        name: value.fname,
        phone: value.fphone.toString()              
      })  
     this.succreg = true;
     alert("تم تحديث البيانات بنجاح")
     window.location.href = "home";
     //document.getElementById("modal_Profile").click();
     return;
   }

}

doforget(value) {
  var auth = firebase.auth();
  //alert(value.frgemail);
  auth.sendPasswordResetEmail(value.frgemail)
  .then(function() {
    // Update successful.
    alert("تم الإرسال إلى بريدك الإلكتروني");
    window.location.href = "home";

  }).catch(function(error) {
    alert("خطأ في البريد الإلكتروني")
  });
    //firebase.auth().
}

doChange(value){
  if (this.registerFormChange.invalid) {
    alert("لطفاً أدخل بيانات صحيحة");
      return;
  }else{
  const user = auth().currentUser;
//alert(user.uid);
//console.log("user : "+user);
 if(value.newpassword == value.repassword){
  user.updatePassword(value.newpassword).then(function() {
    // Update successful.
    alert("تم نغيير كلمة المرور بنجاح");
    window.location.href = "home";

  }).catch(function(error) {
//alert(error);
    // An error happened.
    alert("خطأ في كلمة المرور حاول مرة أَْخرى")
  });
}else{
  alert("كلمتا المرور غير متطابقتان")
}
  }
}
    doRegister(value){
      //alert("sd");
      this.submitted = true;
      if (this.registerFormRegister.invalid) {
        this.errreg = true;
        alert("لطفاً أدخل بيانات صحيحة");
          return;
      }else{
        if(value.password == value.repassword){
       // alert(value.email + "_"+ value.password);
       new Promise<any>((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          //alert("yes");
          resolve(res);
        var uid = res.user.uid;
        this.db.list('Users').set(uid,{
          name: value.name,
          email: value.email,
          phone: value.phone.toString(),
          userType : 0,
          userId:uid,
          userImage:'',
          DOB:'',
          fcmToken:'',
          gender:'',
          os:'web',
          userStatus:0,
          createdAt:new Date().getTime()              
        })
          .then(_ => {
            //this.date = {}
            console.log('success')
            this.registerFormRegister.reset();
            alert("تم التسجيل بنجاح شكرا لك");
          })
        }, err => {
          //alert("er");
          this.errreg = true;
          reject(err)})
      })
    }else{
      alert("يجب تطابق كلمتا المرور"); 
    }
      this.succreg = true;
      return;
    }
  
  } 
    
  
  }
